import React, { useState } from 'react';
import heroImg from '../../img/Home Page/heroImg.svg';
import heroImg2 from '../../img/Home Page/heroImg2.png';
import heroVideo from '../../img/Home Page/heroVideo.webm';
import VisibilitySensor from 'react-visibility-sensor';

const Hero = () => {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    setCursorPosition({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
  };

  return (
    <>
      <div
        className="hero-container"
        style={{
          //height: window.innerWidth >= 600 ? '1028px' : 'auto',
          height: '1028px',
          position: 'relative',
          width: '100%',
          maxWidth: '1920px',
          marginTop: '70px',
          overflow: 'hidden',
        }}
      >
        <div className="hover-image-container" onMouseMove={handleMouseMove}>
          <div className="container d-flex">
            <div className="position-absolute pt-5" style={{ zIndex: 99 }}>
              <div
                className="hero_title"
                style={{
                  paddingTop: '40px',
                  position: 'absolute',
                  width: window.innerWidth >= 1440 ? '750px' : '100%',
                }}
              >
                <VisibilitySensor
                  partialVisibility
                  offset={{ bottom: 100 }}
                  delayedCall
                >
                  {({ isVisible }) => (
                    <>
                      <h2
                        className={`reveal homepage_process_h2 w1460 ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{
                          fontSize: '64px',
                          lineHeight: '64px',
                          color: 'white',
                        }}
                      >
                        Innovative AI <br />
                        Software Solutions <br />
                        for Your Business
                      </h2>

                      <p
                        className="homepage_process_p w1460"
                        style={{ color: 'white' }}
                      >
                        <span
                          style={{ transitionDelay: '40ms' }}
                          className={`reveal ${isVisible ? 'active' : ''}`}
                        >
                          With over 17 years of global design and development
                        </span>
                        <span
                          style={{ transitionDelay: '60ms' }}
                          className={`reveal ${isVisible ? 'active' : ''}`}
                        >
                          expertise delivering valued outcomes for
                        </span>
                        <span
                          style={{ transitionDelay: '80ms' }}
                          className={`reveal ${isVisible ? 'active' : ''}`}
                        >
                          organisations with digital products and services.
                        </span>
                        <span
                          style={{ transitionDelay: '100ms' }}
                          className={`reveal ${isVisible ? 'active' : ''}`}
                        >
                          We specialize in delivering transformative
                        </span>
                        <span
                          style={{ transitionDelay: '120ms' }}
                          className={`reveal ${isVisible ? 'active' : ''}`}
                        >
                          AI-powered digital solutions for organizations.
                        </span>
                      </p>
                    </>
                  )}
                </VisibilitySensor>
              </div>
            </div>
            <div className="w414 position-relative pt-5">
              <div>
                <VisibilitySensor
                  partialVisibility
                  offset={{ bottom: 100 }}
                  delayedCall
                >
                  {({ isVisible }) => (
                    <>
                      <h2
                        className={`reveal homepage_process_h2 ${
                          isVisible ? 'active' : ''
                        }`}
                        style={{
                          fontSize: '48px',
                          lineHeight: '48px',
                          color: 'white',
                        }}
                      >
                        Innovative AI <br />
                        Software Solutions <br />
                        for Your Business
                      </h2>

                      <p
                        className="homepage_process_p w414"
                        style={{ color: 'white' }}
                      >
                        <span className="reveal">
                          With over 17 years of global design
                        </span>
                        <br />
                        <span className="reveal">
                          and development expertise,{' '}
                        </span>
                        <br />
                        <span className="reveal">
                          delivering valued outcomes for{' '}
                        </span>
                        <br />
                        <span className="reveal">
                          organisations with digital products{' '}
                        </span>
                        <br />
                        <span className="reveal">and services.</span>
                        <br />
                        <span className="reveal">
                          We specialize in delivering transformative
                        </span>
                        <br />
                        <span className="reveal">
                          AI-powered digital solutions
                        </span>
                        <br />
                        <span className="reveal">for organizations.</span>
                      </p>
                    </>
                  )}
                </VisibilitySensor>
              </div>
              <div>
                <img src={heroImg} alt="Hovertop" style={{ width: '100%' }} />
              </div>
            </div>
          </div>
          <div
            className="w1460"
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <div className="hover-image">
              <img
                src={heroImg}
                alt="Hovertop"
                style={{ paddingTop: '264px', paddingLeft: '4px' }}
              />
              <video
                src={heroVideo}
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: '100%',
                  position: 'absolute',
                  zIndex: -10,
                }}
              />
            </div>

            <img
              src={heroImg2}
              alt="Hoverbottem"
              className="dynamic-image"
              style={{
                clipPath: `circle(400px at ${cursorPosition.x}px ${cursorPosition.y}px)`,
                WebkitMaskImage: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px, black 50px, transparent 200px)`,
                maskImage: `radial-gradient(circle at ${cursorPosition.x}px ${cursorPosition.y}px, black 50px, transparent 200px)`,
              }}
            />
          </div>
          {/* <div
            className="w414"
            style={{
              position: "relative",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="hover-image">
              <img
                src={heroImg}
                alt="Hovertop"
                style={{ paddingTop: "264px", paddingLeft: "4px" }}
              />
              <video
                src={heroVideo}
                autoPlay
                loop
                muted
                playsInline
                style={{
                  width: "100%",
                  position: "absolute",
                  zIndex: -10,
                }}
              />
            </div>
          </div> */}
        </div>
      </div>

      {/* <div style={{ height: "900px" }}>
        <img
          src={heroImg}
          alt="jx_design_proocess_01"
          className="position-absolute"
        />
        <div
          className="position-relative"
          style={{ width: "638px", paddingTop: "40px" }}
        >
          <h2 className="homepage_process_h2">
            Innovative AI Software Solutions for Your Business
          </h2>
          <p className="homepage_process_p w1460">
            <span className="reveal">
              With over 17 years of global design and development expertise
            </span>
            <span className="reveal">
              delivering valued outcomes for organisations with digital products
              and services. Our
            </span>
            <span className="reveal">
              we specialize in delivering transformative AI-powered digital
              solutions for organizations.
            </span>
          </p>
          <p className="homepage_process_p w414">
            <span className="reveal">With over 17 years of global design</span>
            <br />
            <span className="reveal">and development experience, we have </span>
            <br />
            <span className="reveal">been delivering valued outcomes for </span>
            <br />
            <span className="reveal">organisations with digital products </span>
            <br />
            <span className="reveal">and services.</span>
            <br />
            <span className="reveal">
              Our process is tried and true, focusing on
            </span>
            <br />
            <span className="reveal">
              a collaborative human centred approach.
            </span>
            <br />
            <span className="reveal">
              Following a four step approach: Business
            </span>
            <br />
            <span className="reveal">
              & Digital Strategy, Experience Design and
            </span>
            <br />
            <span className="reveal">
              Managed Services, we are able to help
            </span>
            <br />
            <span className="reveal">you grow better digital experiences.</span>
          </p>
        </div>
      </div> */}
    </>
  );
};

export default Hero;
